import { Controller } from "@hotwired/stimulus"
import { patch, post } from "@rails/request.js"

export default class extends Controller {
  static targets = ['offcanvas']
  saveChange = false;

  connect() {
    this.setdefaultTabContractId();
    this.element.addEventListener('set-current-tab-contract-id', this.setCurrentTabContractId.bind(this))
  }

  toggleCanvas(e) {
    const { contractId } = e.currentTarget.dataset;
    $(`#contract-edit-${contractId}`).toggleClass("hidden")
  }

  preSubmitForm() {
    document.getElementById('saving-contract').setAttribute('disabled', true)
    event.target.form.requestSubmit();
  }

  Review(e) {
    window.location.href = "?expiring_in=90"
    // let contractRows = document.querySelectorAll('tr.contract-expiring')
    // let noContractBanner = document.getElementById("no-contracts-banner")

    // if (contractRows.length === 0) {
    //   noContractBanner.classList.remove("hidden")
    //   setTimeout(() => {
    //     noContractBanner.classList.add("hidden")
    //   }, 1500);
    // } else {
    //   contractRows.forEach(row => {
    //     row.style.backgroundColor = '#FEF2F280';
    //     row.querySelector('.contract-expiring-badge').classList.remove('hidden')
    //     row.querySelector('div.expiring-name').classList.remove('items-center')
    //     row.querySelector('div.expiring-name').classList.add('flex-col')
    //   });
    // }
  }

  moveContractFile(e) {
    const contractFileId = document.getElementById('moveFileModal').dataset.contractFileId;
    const vendorPublicId = document.getElementById('moveFileModal').dataset.vendorPublicId;
    const { contractName, contractId, modalId } = e.currentTarget.dataset

    patch(`/contract_files/${contractFileId}/move.json`, {
      body: JSON.stringify({ contract_file: { contract_id: contractId } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (response.ok) {
        document.getElementById(modalId).style.display = 'none'
        sessionStorage.setItem('showBanner', 'true');
        sessionStorage.setItem('bannerMessage', `Document successfully moved to ${contractName}`);
        window.location.href = `/vendors/${vendorPublicId}`;
      } else {
        throw new Error('Failed to move contract');
      }
    })
    .catch(error => {
    })
    .finally(() => {
    });
  }

  deleteContractFile(e) {
    const contractFileId = document.getElementById('removeDocument').dataset.contractFileId;
    const vendorPublicId = document.getElementById('removeDocument').dataset.vendorPublicId;
    const contractFileName = document.getElementById('removeDocument').dataset.contractFileName;
    fetch(`/contract_files/${contractFileId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (response.ok) {
        sessionStorage.setItem('showBanner', 'true');
        sessionStorage.setItem('deletedMessage', 'true');
        sessionStorage.setItem('bannerMessage', `${contractFileName} successfully deleted`);
        window.location.href = `/vendors/${vendorPublicId}`;
      } else {
        throw new Error('Failed to delete document');
      }
    })
    .catch(error => {
      console.error('Error deleting document:', error);
    });
  }

  updateContractFile(e) {
    const { contractFileId, contractFileName, vendorPublicId, statusParam } = e.currentTarget.dataset
    const statusMessages = {
      analyzing: `${contractFileName} is being re-analyzed in the background. This may take a moment. You may exit, and your changes will still be processed in the background`
    };

    patch(`/contract_files/${contractFileId}.json`, {
      body: JSON.stringify({ contract_file: { status: statusParam } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (response.ok) {
        sessionStorage.setItem('showBanner', 'true');
        sessionStorage.setItem('bannerMessage', `${statusMessages[statusParam]}`);
        window.location.href = `/vendors/${vendorPublicId}`;
      } else {
        throw new Error('Failed to update document');
      }
    })
    .catch(error => {
      console.error('Error updating document:', error);
    });
  }

  setdefaultTabContractId() {
    const contractPublicId = this.element.dataset.contractsOpenTab
    if (contractPublicId) {
      const currentDatetime = new Date().toISOString();
      this.updateContract(contractPublicId, 'last_viewed_at', currentDatetime);
    }
  }

  setCurrentTabContractId(e) {
    const { contractPublicId } = event.detail;
    if (contractPublicId) {
      const currentDatetime = new Date().toISOString();
      this.updateContract(contractPublicId, 'last_viewed_at', currentDatetime);
    }
  }

  updateContractReviewInput(e) {
    const { contractPublicId, reviewParam, vendorPublicId } = e.currentTarget.dataset
    if (contractPublicId && reviewParam) {
      const redirectTo = `/vendors/${vendorPublicId}`
      const bannerMsg = "Successfully updated contract"
      this.updateContract(contractPublicId, 'review_input', reviewParam, redirectTo, bannerMsg);
    }
  }

  updateContract(contractPublicId, columnName, paramVal, redirectTo = null, bannerMsg = null) {
    patch(`/contracts/${contractPublicId}.json`, {
      body: JSON.stringify({ contract: { [columnName]: paramVal }}),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (response.ok) {
        console.log('Successfully updated!');
        if (redirectTo) {
          window.location.href = redirectTo
        }
        if (bannerMsg) {
          sessionStorage.setItem('showBanner', 'true');
          sessionStorage.setItem('bannerMessage', bannerMsg);
        }
      } else {
        throw new Error('Failed to update!');
      }
    })
    .catch(error => {
      console.error('Error updating!:', error);
    });
  }
}
