import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  saveChange = false;

  registerVendorNameEvents = () => {
    $('#rename-vendor[role=menuitem]').on('click', (e) => {
      let vendorRow = $(e.currentTarget).parents(".vendor-info")[0]
      $(vendorRow).find(".vendor-name-container").addClass('hidden')
      $(vendorRow).find('#vendor-name-input-classic').removeClass('hidden')
      $(vendorRow).find('#vendor-name-input-classic').focus()
    })

    $('#vendor-name-input-classic').on('keyup', (e) => {
      let target = e.currentTarget
      if(e.key === 'Enter') {
        e.preventDefault()
        target.blur()
        target.setAttribute('readonly', true)
        const body = {
          vendor: { name: target.value }
        }
        patch(`/vendors/${target.dataset.vendorId}.json`, { body }).then((response) => {
          if(response.ok) {
            $(target).parents('.vendor-info').find('.vendor-name').text(target.value)
          }
          $(target).parents('.vendor-info').find(".vendor-name-container").removeClass('hidden')
          target.removeAttribute('readonly')
          $(target).addClass('hidden')
        })
      }
    })
  }

  connect() {
    this.registerVendorNameEvents()
  }
}
