import { Controller } from "@hotwired/stimulus"
import { get, patch, post } from "@rails/request.js"

export default class extends Controller {
  static targets = ['email', 'button', 'span', 'countryCode', 'phoneNumber']

  async edit(e) {
    const field = e.currentTarget.dataset.fieldName
    let input = document.getElementById(`${field}-input`)

    if (!input.classList.value.includes('hidden')) {
      input.dispatchEvent(new KeyboardEvent('keyup', { key: 'Enter' }))
    } else {
      this.toggleFields(field)
      this.toggleAllFieldsExcept(field)
      input.focus()
      let len = input.value.length
      input.setSelectionRange(len, len);
    }
  }

  connect() {
    if(window.location.href.includes('verify_code')) {
      document.getElementById('verifyCode').style.display = 'block'
    }

    if(this.hasButtonTarget) {
      this.on = this.buttonTarget.dataset.alertEnabled == 'false' ? false : true
      this.updateClass()
    }
    const showPage = document.getElementById("showPage")
    if(showPage && showPage.getAttribute("data-open-modal") === "true") {
      document.getElementById('passwordModal').style.display = 'block';
    }
  }

  async toggle(e) {
    this.on = !this.on;
    const { expiringIn } = e.currentTarget.dataset
    const body = {alert: {
      expiring_in: expiringIn,
      enabled: this.on
    }}

    const response = await post(`/alerts`, { body, dataType: "json" })
    if (response.ok) {
      this.updateClass(e);
    } else {
      console.log('something went wrong')
    }
  }

  updateClass(e) {
    this.buttonTarget.classList.toggle("bg-blue-700", this.on)
    this.buttonTarget.classList.toggle("bg-gray-200", !this.on)
    this.spanTarget.classList.toggle("translate-x-5", this.on)
    this.spanTarget.classList.toggle("translate-x-0", !this.on)
  }

  async update(e) {
    if (e.type === "keyup" && e.key === "Enter") {
      const value = e.currentTarget.value
      const field = e.currentTarget.dataset.fieldName

      const ele = document.getElementById(`${field}-input`)

      if(value.trim() === '') {
        if(!(ele?.nextElementSibling?.tagName.toLowerCase() === 'p')) {
          var error = document.createElement('p');
          error.textContent = 'This filed is required';
          error.classList.add('text-sm', 'mt-1', 'text-red-600');
          ele.insertAdjacentElement('afterend', error);
        }
        return
      } else if(ele?.nextElementSibling?.tagName.toLowerCase() === 'p' && field != 'email') {
        ele?.nextElementSibling.remove()
      }

      var data = {};
      if(field == 'companyName') {
        data = {
          'owned_accounts_attributes': {
            '0' :{
              'name': value,
              'id': e.currentTarget.dataset.accountId
            }
          }
        }
      } else if (field == 'alternativeCompanyName') {
          data = {
          'owned_accounts_attributes': {
            '0': {
              'alternative_name': value,
              'id': e.currentTarget.dataset.accountId
            }
          }
        }
      } else if (field == 'companyWebsite') {
         data = {
          'owned_accounts_attributes': {
            '0' :{
              'website': value,
               'id': e.currentTarget.dataset.accountId
            }
          }
        }
      } else {
        data = {[field]: value}
      }
      const response = await patch(`/users`, {
        body: {user: data},
        contentType: "application/json"
      })

      if (response.ok) {
        var element = document.getElementById(`${field}-update`)
        element.textContent = 'Updated'
        element.classList.add('text-green-600')
        element.classList.remove('text-blue-700')

        setTimeout(function() {
          element.textContent = 'Update'
          element.classList.add('text-blue-700')
          element.classList.remove('text-green-600')
        }, 3000)

        document.getElementById(field).textContent = value
        if(field == 'email') {
          this.showErrorMsg(field)
        }
      } else {
        if(field == 'email') {
          document.getElementById(`${field}-error`).textContent = `This email has already been taken.`
          this.showErrorMsg(field)
        }
      }
      this.toggleFields(field)
      this.toggleAllFieldsExcept(field)
    }
  }

  showErrorMsg(field) {
    document.getElementById(`${field}-error`).classList.remove('hidden')
    setTimeout(function() {
      document.getElementById(`${field}-error`).classList.add('hidden')
    }, 3000)
  }

  toggleFields(field) {
    document.getElementById(`${field}-input`).classList.toggle('hidden')
    document.getElementById(field).classList.toggle('hidden')
  }

  toggleAllFieldsExcept(field) {
    const fields = this.element.querySelectorAll(`[data-field-name]:not([data-field-name="${field}"])`);

    fields.forEach(fieldElement => {
      const fieldName = fieldElement.dataset.fieldName;
      const input = document.getElementById(`${fieldName}-input`);
      const staticText = document.getElementById(fieldName);

      if (!input.classList.contains('hidden')) {
        input.classList.add('hidden');
        staticText.classList.remove('hidden');
      }
    });
  }

  openModal(e) {
    const { modalId, modalTitle, sourceType, resourceId, vendorId, contractFileId, contractFileName, vendorPublicId, subscribedPlan } = e.currentTarget.dataset
    if(sourceType === 'document') {
      document.getElementById('newContract').dataset["sourceType"] = sourceType
      document.getElementById('newContract').dataset["contractId"] = resourceId
    }
    if(contractFileId) {
      document.getElementById('moveFileModal').dataset["contractFileId"] = contractFileId
      document.getElementById('removeDocument').dataset["contractFileId"] = contractFileId
    }
    if(vendorPublicId) {
      document.getElementById('moveFileModal').dataset["vendorPublicId"] = vendorPublicId
      document.getElementById('removeDocument').dataset["vendorPublicId"] = vendorPublicId
    }
    if(contractFileName) {
      document.getElementById('removeDocument').dataset["contractFileName"] = contractFileName
    }
    if(vendorId)
      document.getElementById('newContract').dataset["vendorId"] = vendorId
    else if(document.getElementById('newContract'))
      delete document.getElementById('newContract').dataset.vendorId
    const cantScan = document.getElementById('cantScanCode')
    if(modalTitle)
      document.getElementById('modal-title').textContent = modalTitle
    document.getElementById(`${modalId}`).style.display = 'block'
    if(modalId == 'verifyCode') {
      document.getElementById('addAuthenticatorApp').style.display = 'none'
      if (cantScan.style.display == 'block') {
        cantScan.style.display = 'none'
      }
    }
    if(modalId == 'verifyPhone') {
      document.getElementById('addPhoneNumber').style.display = 'none'
    }
  }

  closeModal(e) {
    const id = e.currentTarget.dataset.modalId
    document.getElementById(`${id}`).style.display = 'none'
  }

  goBack(e) {
    document.getElementById('verifyCode').style.display = 'none'
    document.getElementById('addAuthenticatorApp').style.display = 'block'
  }

  async addPhone(e) {
    const target = e.currentTarget;
    if(this.phoneNumberTarget.value.trim() === "") {
      this.phoneNumberTarget.classList.add('border-red-600')
      return;
    }
    const body = {
      phone_number: this.phoneNumberTarget.value,
      country_code: this.countryCodeTarget.value
    }
    e.currentTarget.setAttribute('disabled', true)
    const response = await patch(`/user/two_factor/send_verification_code`, { body, dataType: "json" })
    if(response.ok) {
      if(e.target.dataset.url) {
        window.location.href = e.target.dataset.url
      } else {
        window.location.reload()
      }
    } else {
      const errorMessage = await response.text
      let msg = JSON.parse(errorMessage)
      document.getElementById(`verify-phone-error`).innerText = msg.message
      this.showErrorMsg('verify-phone')
      target.removeAttribute('disabled')
    }
  }

  async verifyPhoneNo(e) {
    const target = e.currentTarget
    if(this.phoneNumberTarget.value.trim() === "") {
      this.phoneNumberTarget.classList.add('border', 'border-red-600');
      return;
    }
    const body = {
      verification_code: document.getElementById("phone_verification").value,
      remember_this_device: document.getElementById("remember_me") && document.getElementById("remember_me").checked
    }
    e.currentTarget.setAttribute('disabled', true)
    const response = await post(`/user/two_factor/check_verification_code`, { body, dataType: "json" })
    if(response.ok) {
      if(window.location.href.includes("update_m_setup")) {
        window.location.reload()
      } else {
        window.location.href = '/'
      }
    }
    else {
      const errorMessage = await response.text
      let msg = JSON.parse(errorMessage)
      document.getElementById(`m-verify-phone-error`).innerText = msg.message
      this.showErrorMsg('m-verify-phone')
      target.removeAttribute('disabled')
    }
  }

  async resendCode(e) {
    const body = {
      resend_code: true
    }
    const response = await patch(`/user/two_factor/send_verification_code`, { body, dataType: "json" })
    if(response.ok) {
      document.getElementById("code-sent").classList.remove("hidden")
      setTimeout(function() {
        document.getElementById("code-sent").classList.add("hidden")
      }, 3000)
    }
  }

  async resendOtp(e) {
    const response = await get('/session/otp.json')
    if(response.ok) {
      document.getElementById('send-otp').classList.add('hidden')
      document.getElementById('otp-sent').classList.remove('hidden')
      var seconds = 60
      setTimeout(() => {
        document.getElementById('otp-sent').classList.add('hidden')
        document.getElementById('otp-timer').classList.remove('hidden')
      }, 3000)
      var timer = setInterval(() => {
        seconds = seconds - 1
        document.getElementById('otp-timer').innerText = `0:${seconds}`
        if(seconds == 0) {
          clearInterval(timer)
          document.getElementById('otp-timer').classList.add('hidden')
          document.getElementById('send-otp').classList.remove('hidden')
        }
      }, 1000)
    }
  }
}
