import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  connect () {
    this.registerSelects()
  }

  formatDtSelect2Option = (state) => {
    let description = {
      msa: 'Sets the general rules for how a service provider and client will work together in SaaS.',
      nda: 'Protects confidential information shared between parties in SaaS negotiations or partnerships.',
      dpa: 'Ensures a SaaS provider handles customer data securely and meets data protection laws.',
      sla: 'Defines the expected performance and support level for a SaaS service.',
      eula: 'Explains how users can legally use a SaaS software.',
      of: 'Confirms the specific details of a SaaS purchase, like the product, price, and subscription terms.'
    }
    let tooltip = ''
    if(state.id) {
      tooltip = `
        <button
          data-controller='tooltip'
          data-tippy-allowHtml='true'
          data-tippy-content='
            <p class="text-gray-900"> ${state.text} (${(state.id || '').toUpperCase()})</p>
            <p class="text-gray-400"> ${description[(state.id || '').toLowerCase()]} </p>
          '
          data-tippy-delay='0'
          data-tippy-arrow='true'
          data-tippy-size='regular'
          data-tippy-animation='fade'
        >
          <svg class="peer w-4 h-4 text-gray-400 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
          </svg>
        </button>
      `
    }
    return $(`
      <div class="dt-select2-option flex justify-between items-center">
        <span class="label">
          ${state.text}
        </span>
        ${tooltip}
      </div>
    `)
  }
  formatDtSelect2Selection = state => { return state.id.toUpperCase() }

  dispathChangeEvent = (e) => {
    e.currentTarget.dispatchEvent(new Event('change', { bubbles: false }))
  }

  registerSelects = (e) => {
    var select2Config = { width: '100%', minimumResultsForSearch: -1 };
    $(".select").select2(select2Config).on('select2:select', this.dispathChangeEvent);
    $('.currency-select').select2({
      width: '100%', templateResult: (state) => { return `${state.text} (${state.id?.toUpperCase()})` },
      templateSelection: (state) => { return `${state.id?.toUpperCase()}` }
    }).on('select2:select', this.dispathChangeEvent);

    var vendorSelect2Config = { width: '100%', ajax: {
        url: '/vendors', dataType: 'json', type: 'GET', processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: item.name,
                id: item.id
              }
            })
          };
        }
      }
    }
    $('.vendor_id').select2(vendorSelect2Config).on('select2:select', this.dispathChangeEvent);

    var dfSelect2Config = $.extend({ dropdownCssClass: 'df-select2-dropdown', containerCssClass: 'df-select2 flex items-center' }, select2Config)
    $(".df-select").select2(dfSelect2Config).on('change', function(e) {
      const target = e.currentTarget;
      const filter_type = target.dataset.filterType;
      const value = target.value;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(filter_type, value);
      window.location.href = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
    })
    $(".df-select.status").select2($.extend({}, dfSelect2Config, {
      templateResult: (state) => {
        return $(`
          <input type="radio" id="${state.id}" value="${state.text}" ${state.element?.selected ? 'checked' : '' }>
          <span> ${state.text} </span>
        `)
      }
    }));

    $('.contract-file-type-select').select2($.extend({ containerCssClass: 'flex items-center', templateResult: this.formatDtSelect2Option, templateSelection: this.formatDtSelect2Selection}, select2Config)).on('change', event => {
      const target = event.currentTarget
      $(target).attr('disabled', true)
      patch(`/contract_files/${target.dataset.fileId}/update_document_type.json`, { body: { contract_file: { type: $(target).data('documentType'), new_type: target.value } } }).then(response => {
        if(response.ok) {
          response.json.then(payload => {
            $(target).attr('disabled', false)
            $(target).attr('documentType', target.value)
          })
        } else {
          console.log('Something went wrong.')
        }
      })
    })

    var dtSelect2Config = {
      dropdownCssClass: 'dt-select2-dropdown', containerCssClass: 'dt-select2-selection flex items-center', templateResult: this.formatDtSelect2Option,
      templateSelection: this.formatDtSelect2Selection, minimumResultsForSearch: -1
    }
    $('.document-type').on('click', (e) => {
      $(e.currentTarget).addClass('hidden')
      $(e.currentTarget).siblings('.document-type-select').removeClass('hidden').prop('disabled', false)
      $(e.currentTarget).siblings('.document-type-select').select2(dtSelect2Config).on('change', event => {
        const target = event.currentTarget
        patch(`/contract_files/${target.dataset.fileId}/update_document_type.json`, { body: { contract_file: { type: $(e.currentTarget).data('documentType'), new_type: target.value } } }).then(response => {
          $(e.currentTarget).removeClass('hidden')
          $(target).addClass('hidden').select2(dtSelect2Config).select2('destroy')
          if(response.ok) {
            response.json.then(payload => {
              $(e.currentTarget).text(payload.file.type.toUpperCase())
              $(e.currentTarget).data('documentType', payload.file.type)
            })
          } else {
            console.log('Something went wrong.')
          }
        })
      });
    })
  }
}
