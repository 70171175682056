import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  saveChange = false;

  connect() {
    const renameVendorMenuItem = document.getElementById("rename-vendor");
    const vendorNameInput = document.getElementById("vendor-name-input");
    const vendorNameSavedText = document.getElementById("vendor-name-saved-text");
    const menuDropdown = document.getElementById("menu-button");

    if (renameVendorMenuItem) {
      document.getElementById("rename-vendor").addEventListener("click", () => {
        document.getElementById("vendor-name").classList.add("hidden")
        vendorNameInput.classList.remove("hidden")
        vendorNameInput.removeAttribute("readonly")
        vendorNameInput.click();
        vendorNameInput.focus();
        menuDropdown.classList.add("hidden")
        this.saveChange = true;
      });
    }

    if (vendorNameInput) {
      vendorNameInput.addEventListener("keyup", (event) => {
        if (this.saveChange && event.key === "Enter" && vendorNameInput.value.trim().length > 0) {
          event.preventDefault();
          vendorNameInput.blur();
          vendorNameInput.setAttribute("readonly", true);
          this.saveChange = false;
          const body = {
            vendor: {
              name: vendorNameInput.value
            }
          }
          patch(`/vendors/${window.location.href.split("/").at(-1)}.json`, {
            body
          })
          .then(response => {
            if (response.ok) {
              vendorNameSavedText.classList.remove("hidden");
              menuDropdown.classList.add("hidden");

              setTimeout(() => {
                vendorNameSavedText.classList.add("hidden");
                menuDropdown.classList.remove("hidden");
              }, 1500);

              document.getElementById("vendor-name").textContent = vendorNameInput.value
              document.getElementById("vendor-name-input").classList.add("hidden")
              document.getElementById("vendor-name").classList.remove("hidden")
            } else {
              //this is temporarily added to test in else part, will remove when connect with BE
              vendorNameSavedText.classList.remove("hidden");
              menuDropdown.classList.add("hidden");

              setTimeout(() => {
                vendorNameSavedText.classList.add("hidden");
                menuDropdown.classList.remove("hidden");
              }, 1500);
            }
          })
          .catch(error => {
            console.error("Error:", error);
          });
        }
      });
    }
    this.displaySuccessBanner();
  }

  displaySuccessBanner() {
    const showBanner = sessionStorage.getItem('showBanner');
    const bannerMessage = sessionStorage.getItem('bannerMessage');
    const deletedMessage = sessionStorage.getItem('deletedMessage');

    if (showBanner === 'true') {
      const banner = this.element.querySelector('#move_document_success_banner');
      const bannerText = banner.querySelector('#bannerText');
      const successIcon = banner.querySelector('#successIcon');
      const warningIcon = banner.querySelector('#warningIcon');

      if (deletedMessage === 'true') {
        bannerText.textContent = `${bannerMessage}`;
        banner.classList.remove('bg-green-100', 'border-green-400', 'text-green-700');
        banner.classList.add('bg-red-100', 'border-red-400', 'text-red-700');
        warningIcon.classList.remove('hidden');

      } else {
        bannerText.textContent = `${bannerMessage}`;
        banner.classList.remove('bg-red-100', 'border-red-400', 'text-red-700');
        banner.classList.add('bg-green-100', 'border-green-400', 'text-green-700');
        successIcon.classList.remove('hidden');
      }

      banner.classList.remove('hidden');

      setTimeout(() => {
        banner.classList.add('hidden');
        successIcon.classList.add('hidden');
        warningIcon.classList.add('hidden');
      }, 8000);

      sessionStorage.removeItem('showBanner');
      sessionStorage.removeItem('bannerMessage');
      sessionStorage.removeItem('deletedMessage');
    }
  }
}
